import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
 
const Container = styled.div`
 display: flex;
 height: 100%;
 padding: 10rem 0;
 flex-direction: column;
 justify-content: space-evenly;
 align-items: center;
`

const Item = styled(Link)`
  display: block;
  color: ${props => props.theme.colors.body};
  font-family: "Museo Sans Rounded 500", sans-serif;
  text-transform: uppercase;
  font-size: 5rem;
  transition: all .2s ease;

  &:hover {
    color: ${props => props.theme.colors.text};
    transform: scale(1.07);
  }
  
  @media ${props => props.theme.breaks.down('lg')} {
     font-size: 3.5rem;
  }
`

export default function NavItems () {

  const links = [
    {
      name: "We",
      path: "/we"
    },
    {
      name: "Complete",
      path: "/complete"
    },
    {
      name: "You",
      path: "/you"
    },
    {
      name: "With",
      path: "/with"
    },
    {
      name: "Closets",
      path: "/closets"
    },
    {
      name: "Contact Us",
      path: "/contact-us"
    },
  ]
 
 return (
 <Container>
   {links && links.map(({name, path}) => 
    <Item key={name} to={path}>{name}</Item>
   )}
   
 </Container>
 )
}