// import {createMuiTheme} from '@material-ui/core'
const size = {
  smp: '450px',
  sm: '600px',
  md: '960px',
  lg: '1280px',
  xl: '1920px',
}

export const theme = {
  colors: {
    body: '#FFFFFF',
    text: '#000000',
    color: '#2BA2C3'
  },
  breaks: {
    up: bp => `(min-width: ${size[bp]})`,
    down: bp => `(max-width: ${size[bp]})`,
    bewteen: (bp1, bp2) => `(min-width: ${size[bp1]}) and (max-width: ${size[bp2]})`,
    only: bp => `(width: ${size[bp]})`
  },
  maxWidth: '1280px',
  maxWidthMd: '960px'
}

// console.log(theme);