/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

 import React from "react"
 import { Helmet } from "react-helmet"
 import PropTypes from "prop-types"
 import { useStaticQuery, graphql } from "gatsby"
 import styled from "styled-components"

 
 import { ThemeProvider } from "styled-components";
 import { theme } from "../utils/theme"
 
 import favicon from '../images/favicon.ico';
 import appleFav from '../images/apple-touch-icon.png';
 import fav32 from '../images/favicon-32x32.png';
 import fav16 from '../images/favicon-16x16.png';
 

//  import Contact from '../components/contact-button';
 
 import "../css/main.scss"

 const Container = styled.div`
  height: 100%;
  position: relative;
 `
 const Layout = ({ children, pageTitle, contact = true}) => {
 
   const data = useStaticQuery(graphql`
     query SiteTitleQuery {
       site {
         siteMetadata {
           title
           description
         }
       }
     }
   `)
 
 const schema = {
   "@context": "https://schema.org",
   "@type": "Corporation",
   "name": "Create A Space",
   "alternateName": "create_a_space",
   "url": "https://www.createaspace.xyz",
   "description": data.site.siteMetadata.description,
   // "logo": "https://d-wdesign.com/static/logo-4d8d3da1811ecc735168581dd1114e25.png",
   "sameAs": [
     "https://www.instagram.com/celebrand/",
    //  "https://www.facebook.com/celebrand.us",
    //  "https://www.linkedin.com/company/celebrand"
     ]
   }
 
   return (
     <ThemeProvider theme={theme}>
     {/* <CssBaseline /> */}

       <div>

        <Helmet htmlAttributes={{
          lang: 'en',
        }}
        title={pageTitle || data.site.siteMetadata.title}
        >
          <meta property="og:title" content={pageTitle ? pageTitle : data.site.siteMetadata.title} />
          
          <link rel="icon" href={favicon} />
          <link rel="apple-touch-icon" sizes="180x180" href={appleFav} />
          <link rel="icon" type="image/png" sizes="32x32" href={fav32} />
          <link rel="icon" type="image/png" sizes="16x16" href={fav16}/>
          <meta property="og:description" content={data.site.siteMetadata.description} />
          <meta name="description" content={data.site.siteMetadata.description} />
  
          <title>{pageTitle || data.site.siteMetadata.title}</title>
  
  
          {schema && <script type="application/ld+json">
            {JSON.stringify(schema)}
          </script>}

          <noscript>
          {`
              <style>
              .img-noscript {
                visibility: visible !important;
              }
              
              .img-container {
                visibility: visible !important;
              }
              </style>
          `}
          </noscript>
          
        </Helmet>

        <Container>
          <main className="main-section">{children}</main>

          {/* {contact ? <Contact type="desk" /> : null} */}
        </Container>

       </div>
       </ThemeProvider>
   )
 }
 
 Layout.propTypes = {
   children: PropTypes.node.isRequired,
 }
 
 export default Layout
 